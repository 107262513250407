<template>
    <Component
        :is="element"
        :to="link || undefined"
        class="base-card"
        draggable="false"
        :class="{
            'base-card--compact': spacing === 'compact'
        }"
        @mouseover="showMainImage = false"
        @mouseleave="showMainImage = true"
    >
        <div
            v-if="hoverableImage && images.length > 0 || $slots.image"
            :class="{
                'base-card__hoverable-image': hoverableImage
            }"
        >
            <div
                v-if="!!$slots.ticker"
                class="base-card__ticker"
            >
                <div>
                    <slot name="ticker" /> • 
                    <slot name="ticker" /> • 
                    <slot name="ticker" /> • 
                    <slot name="ticker" /> • 
                    <slot name="ticker" /> • 
                </div>
                <div>
                    <slot name="ticker" /> • 
                    <slot name="ticker" /> • 
                    <slot name="ticker" /> • 
                    <slot name="ticker" /> • 
                    <slot name="ticker" /> • 
                </div>
            </div>
            <slot name="image">
                <transition name="base-card">
                    <BaseImage
                        v-if="images?.[0]?.[0]"
                        v-show="showMainImage || !images?.[1]?.[0]"
                        :image-attrs="{ draggable: false }"
                        :sizes="imageSizes"
                        :image="images[0][0]"
                        :width="images[0][0].width"
                        :height="images[0][0].height"
                        :fade-in-view="false"
                    />
                </transition>
                <transition name="base-card">
                    <BaseImage
                        v-if="images?.[1]?.[0]"
                        v-show="!showMainImage"
                        :image-attrs="{ draggable: false }"
                        loading="lazy"
                        :sizes="imageSizes"
                        :image="images[1][0]"
                        :width="images[1][0].width"
                        :height="images[1][0].height"
                        :fade-in-view="false"
                    />
                </transition>
            </slot>
        </div>

        <div
            v-else-if="images.length > 0"
            v-motion
            class="base-card__image"
            :initial="{ 
                opacity: 0, 
                y: 0, 
                scale: 1.05 
            }"
            :visible="{ 
                opacity: 1, 
                y: 0, 
                scale: 1, 
                transition: {
                    duration: 1000,
                    ease: '0.4, 0, 0.2, 1',
                    delay: 0
                }
            }"
        >
            <BaseImage
                :fade-in-view="false"
                :sizes="imageSizes"
                :image="images[0]"
                :title="images[0].title"
                :width="images[0].width"
                :height="images[0].height"
            />
        </div>

        <article class="base-card__content">
            <div
                class="base-card__body"
            >
                <slot name="header" />

                <p
                    v-if="!!$slots.description"
                    class="base-card__description"
                >
                    <slot name="description" />
                </p>
            </div>

            <div
                v-if="!!$slots.amenities"
                class="base-card__amenities"
            >
                <slot name="amenities" />
            </div>
            <div
                class="base-card__footer"
            >
                <div
                    v-if="!!$slots.meta"
                    class="base-card__footer-meta"
                >
                    <!-- TODO: refactor to amenities? -->
                    <slot name="meta" />
                </div>

                <div
                    v-if="!!$slots.link"
                    class="base-card__footer-link"
                >
                    <slot name="link" />
                </div>
            </div>
        </article>
    </Component>
</template>

<script setup>
import BaseImage from './BaseImage.vue';
import { ref, computed } from 'vue';

const props = defineProps({
    images: {
        type: Array,
        required: false,
        default: () => []
    },
    link: {
        type: [String, Object],
        default: null
    },
    spacing: {
        type: String,
        default: null,
        validator(value) {
            ['compact'].includes(value);
        }
    },
    hoverableImage: {
        type: Boolean,
        default: true
    },
    imageSizes: {
        type: String,
        default: '(min-width: 1440px) 320px, 230px'
    }
});

defineOptions({
    name: 'BaseCard',
});

const showMainImage = ref(true);

// Preload the second image if there is one
if (props.images?.[1]?.url) {
    useHead({
        link: [
            { rel: 'preload', as: 'image', href: props.images[1].url }
        ]
    });
}

// If there is no link, use a div instead of a link
const element = computed(() => {
    return props.link ? resolveComponent('CraftLink') : 'div';
});
</script>

<style lang="less" src="./BaseCard.less" />
